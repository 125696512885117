import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Fixed Income Program"/>
            <Banner title="Fixed Income Program"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">This solutions package is oriented to the companies that have already
                            entered the debt market and are willing to implement an IR program, either to execute a new
                            debt issuance more effectively or lay down the foundations for an IPO. IRStrat’s solution
                            focuses on improving the visibility in the markets to increase the demand for the
                            instruments, achieving an improved trading (greater liquidity of the bond, as well as better
                            relative valuations), and expanding the institutional investor base. This package
                            includes:</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service_left_sidebar">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Package 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Package 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Package 3</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete4-tab" data-toggle="tab" href="#paquete4"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Package 4</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion"
                                                               href="#collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic core
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse1" className="collapse show" aria-expanded="true"
                                                         role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Investment
                                                                    thesis development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Shaping of
                                                                    competitive advantage narrative
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Crafting of the
                                                                    history narrative
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Development of
                                                                    long-term guidance
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Communication
                                                                    style shaping (qualitative and quantitative mix)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>C-Suite communications
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Market
                                                                    communication training for senior management
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparation and
                                                                    consultancy on forums and presentations to investors
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Quarterly
                                                                    earnings speechwriting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Speech drafting
                                                                    and consultancy on corporate transactions (M&As,
                                                                    divestitures and other corporate events)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Crisis
                                                                    management consultancy
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Structuring of policies,
                                                                procedures and metrics
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Disclosure
                                                                    policy development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Core functions
                                                                    mapping
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>IR flowchart
                                                                    creation (functions and methodology)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definition of
                                                                    key performance indicators (KPIs)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    corporate governance
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Market reporting and
                                                                day-to-day communications
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Earnings
                                                                    release drafting and circle-up
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Annual report
                                                                    and regulatory filings drafting and circle-up
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Investors
                                                                    presentations drafting and quarterly updating
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Quarterly
                                                                    updates of investment fact sheet
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    communication and development of annual guidance
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Press releases
                                                                    drafting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Contact point
                                                                    for investors
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    interviews (traditional and social media)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p2_accordion"
                                                               href="#p2_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p2_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Communication and strategic
                                                                disclosure</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Wires
                                                                    publishing strategy
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Distribution of
                                                                    announcements on quarterly earnings reports and
                                                                    conference calls
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Outreach to
                                                                    financial and specialized media
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Distribution
                                                                    list management
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p2_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Regulatory compliance</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with CNBV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with CONSAR
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with BMV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with BIVA
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with SEC
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Investor profiling</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Execution of
                                                                    perception studies
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Structuring of
                                                                    shareholders proxy
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparation of
                                                                    briefing books on investors
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Post-earnings
                                                                    release surveys
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Investor prospecting</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Buy-side
                                                                    investors targeting (fixed income and equity)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    road shows and non-deal road shows
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to stock promoters
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Distribution
                                                                    lists management (creation and development)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    and events with pension and investment funds
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p3_accordion"
                                                               href="#p3_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p3_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Analyst coverage
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Valuation
                                                                    models development (DCFs and nodes)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Sell-side
                                                                    investors targeting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to analysis departments
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p3_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Own events
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Planning and
                                                                    logistics for investor days
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planning and
                                                                    logistics for site visits
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planning and
                                                                    logistics for press conferences
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Benchmarking</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    annual results (industry, market and region)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    quarterly results (industry, market and region)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    market sentiment (positioning and visibility)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete4" role="tabpanel"
                                     aria-labelledby="paquete4-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p4_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p4_accordion"
                                                               href="#p4_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p4_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic agenda
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>National and
                                                                    international financial events (summits,
                                                                    roundtables, forums, and so on)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Participative
                                                                    lobbying (we seek visibility)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p4_accordion" href="#p4_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p4_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>IR website
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Structuring and
                                                                    graphical development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Content
                                                                    drafting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Optimized
                                                                    platform for mobile devices
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Interactive
                                                                    tools (valuation multiples, key performance
                                                                    indicators and stock information)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p4_accordion" href="#p4_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>IR Edge 3.0 services</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Web Analytics
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Dedicated
                                                                    servers in the “cloud”
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Translation
                                                                    services (Spanish-English)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Intelligence
                                                                    interface
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
